<template>
  <div class="page">
    <section>
      <el-input
        autocomplete="off"
        class="input"
        placeholder="请输入内容"
        @change="searchBtn"
        clearable
        v-model="title"
      >
        <el-button @click="searchBtn" @clear="searchBtn" slot="append"
          >搜索</el-button
        >
      </el-input>
      <el-tabs
        :before-leave="() => !loading"
        v-model="activeName"
        @tab-click="handleClick"
      >
        <!-- 综合 -->
        <el-tab-pane label="综合" name="all">
          <div class="content" v-loading="loading">
            <div v-if="!list.length">
              <div class="null-box">
                <img
                  :src="
                    $http.baseURL + '/static/images/web/ecf28ef18a8e85ff.png'
                  "
                  alt=""
                />
                <div class="title">暂无相关内容</div>
              </div>
            </div>

            <div v-for="(item, index) in listFilter[page - 1]" :key="index">
              <!-- 视频 -->
              <div
                class="left-group"
                v-if="item.search_type == 1"
                @click="xin.goUrl('/found-details-videoDetails?id=' + item.id)"
              >
                <div class="content2">
                  <div class="left">
                    <div class="play">
                      <i class="iconfont zanting"></i>
                    </div>
                    <img class="banner2" :src="item.video_img" />
                  </div>
                  <div class="right">
                    <span class="title" ref="investigateRef">{{
                      item.title
                    }}</span>
                    <span class="subTitle" v-html="item.content"></span>
                    <div class="subTitle2">
                      <span>{{ item.browse_num }}浏览</span>
                      <span class="time">{{
                        item.create_time | timeFormat
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <!-- 用户 -->
              <div
                class="group"
                v-if="item.search_type == 2"
                style="margin-bottom: 30px; border-bottom: 1px solid #f2f2f2"
              >
                <img :src="item.avatar" alt="" />
                <div class="intermediate">
                  <span ref="investigateRef">{{ item.nickname }}</span>
                  <div class="numberFans">
                    <span>粉丝数：</span>
                    <span>{{ item.attention_num }}</span>
                  </div>
                </div>
                <span
                  v-if="item.user_id != userInfo.id"
                  class="attention"
                  :class="{ attention1: item.attention }"
                  @click="attentionFn(item)"
                  >{{ item.attention ? "已关注" : "关注" }}</span
                >
              </div>

              <!--调研 -->
              <div
                class="list"
                v-if="item.search_type == 3"
                @click="researchFn(item, 1)"
              >
                <div class="img">
                  <img class="banner2" :src="item.url" />
                  <div class="mark">
                    <span>{{ arr[item.type - 1] }}</span>
                  </div>
                </div>
                <div class="left-onLine-right">
                  <div ref="investigateRef" class="inerhtml">
                    <span class="sheng">{{ item.title }}</span>
                    <span class="subTitle sheng_2" v-html="item.present"></span>
                  </div>
                  <div class="right-button">
                    <span class="blue left-btn">{{ item.duration }}分钟</span>
                    <span class="blue left-btn">¥{{ item.cash_gift }}</span>
                    <span
                      class="blue recommend-yellow"
                      @click.stop="researchFn(item, 2)"
                      >我要推荐</span
                    >
                  </div>
                </div>
              </div>

              <!-- 新闻 -->
              <div
                v-if="item.search_type == 4"
                class="left-group"
                @click="xin.goUrl('/index-newsDetail?id=' + item.id)"
              >
                <div v-if="item.video" class="">
                  <div class="content2">
                    <div class="img-s">
                      <img class="banner2" :src="item.video_images" />
                      <div class="rectangular">
                        <img
                          :src="$http.baseURL + '/static/images/web/2x1.png'"
                          alt=""
                        />
                      </div>
                    </div>
                    <div class="right">
                      <span class="title" ref="investigateRef">{{
                        item.title
                      }}</span>
                      <span
                        class="subTitle"
                        ref="investigateRef"
                        v-html="item.subtitle"
                      ></span>
                      <div class="subTitle2">
                        <span>{{ item.info_count | pageviews }}浏览</span>
                        <span class="time">{{
                          item.create_time | formaTime
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else class="">
                  <span class="title" ref="investigateRef">{{
                    item.title
                  }}</span>
                  <div class="content">
                    <img class="banner" :src="item.images" />
                    <div class="right">
                      <span
                        v-html="item.subtitle"
                        class="subTitle"
                        ref="investigateRef"
                      >
                      </span>
                      <div class="subTitle2">
                        <span>{{ item.info_count | pageviews }}浏览</span>
                        <span class="time">{{
                          item.create_time | formaTime
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- 发现 -->
              <div v-if="item.search_type == 5" class="left-group">
                <!-- 发布说说 -->
                <div
                  class="group-one"
                  v-if="item.type == 1"
                  @click="xin.goUrl('/found-details-details?id=' + item.id)"
                >
                  <div v-if="item.images.length" class="group-one-box2">
                    <img class="banner2" :src="item.images[0]" />
                    <div class="rectangular2">
                      <span v-if="item.images">{{ item.images.length }}图</span>
                    </div>
                  </div>
                  <div class="group-one-right">
                    <div class="group-one-top" v-if="item.doctor">
                      <img
                        class="avatar"
                        :src="
                          item.doctor.photo
                            ? item.doctor.photo
                            : require('@image/default.png')
                        "
                        @click.stop="goPersonal"
                      />
                      <div class="group-one-top-right">
                        <div class="one">
                          <span class="title">{{ item.doctor.name }}</span>
                          <span class="position">{{
                            item.doctor.title_name
                          }}</span>
                        </div>
                        <span class="address"
                          >{{ item.doctor.company }}
                          {{ item.doctor.department_name }}</span
                        >
                      </div>
                    </div>
                    <span
                      class="subTitle"
                      ref="investigateRef"
                      :class="{ line: item.images.length }"
                      v-html="item.content"
                    ></span>
                    <div class="data" style="padding-bottom: 10px">
                      <span>{{ item.praise_num }}赞</span>
                      <span>·</span>
                      <span>{{ item.comment_num }}评论</span>
                      <span>·</span>
                      <span>{{ item.browse_num | pageviews }}浏览</span>
                      <span>·</span>
                      <span>{{ item.create_time | formaTime }}</span>
                    </div>
                  </div>
                </div>

                <!-- 病例分享 -->
                <div
                  class="group-one-right2"
                  v-if="item.type == 2"
                  @click="xin.goUrl('/found-details-details?id=' + item.id)"
                >
                  <div class="group-one-top" v-if="item.doctor">
                    <img
                      class="avatar"
                      :src="
                        item.doctor.photo
                          ? item.doctor.photo
                          : require('@image/default.png')
                      "
                      @click.stop="goPersonal"
                    />
                    <div class="group-one-top-right">
                      <div class="one">
                        <span class="title">{{ item.doctor.name }}</span>
                        <span class="position">{{
                          item.doctor.title_name
                        }}</span>
                      </div>
                      <span class="address"
                        >{{ item.doctor.company }}
                        {{ item.doctor.department_name }}</span
                      >
                    </div>
                  </div>
                  <span
                    ref="investigateRef"
                    class="subTitle"
                    style="margin-top: 20px"
                    v-html="item.title"
                  ></span>
                  <span class="subTitle2" v-html="item.content"></span>
                  <div class="data">
                    <span>{{ item.praise_num }}赞</span>
                    <span>·</span>
                    <span>{{ item.comment_num }}评论</span>
                    <span>·</span>
                    <span>{{ item.browse_num | pageviews }}浏览</span>
                    <span>·</span>
                    <span>{{ item.create_time | formaTime }}</span>
                  </div>
                </div>

                <!-- 学术视频 -->
                <div
                  class="group-one"
                  v-if="item.type == 3"
                  style="width: 1140px"
                  @click="
                    xin.goUrl('/found-details-videoDetails?id=' + item.id)
                  "
                >
                  <div class="group-one-box">
                    <img class="banner" :src="item.video_img" />
                    <div class="rectangular">
                      <img src="@image/bofang.png" />
                    </div>
                  </div>
                  <div class="group-one-right">
                    <div class="group-one-top" v-if="item.doctor">
                      <img
                        class="avatar"
                        :src="
                          item.doctor.photo
                            ? item.doctor.photo
                            : require('@image/default.png')
                        "
                        @click.stop="goPersonal"
                      />
                      <div class="group-one-top-right">
                        <div class="one">
                          <span class="title">{{ item.doctor.name }}</span>
                          <span class="position">{{
                            item.doctor.title_name
                          }}</span>
                        </div>
                        <span class="address"
                          >{{ item.doctor.company }}
                          {{ item.doctor.department_name }}</span
                        >
                      </div>
                    </div>
                    <div
                      class="subTitle"
                      v-html="item.title"
                      ref="investigateRef"
                    ></div>
                    <div
                      style="
                        line-height: 32px;
                        width: 100%;
                        text-overflow: -o-ellipsis-lastline;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        color: #8f8f8f;
                        font-szie: 16px;
                      "
                    >
                      {{ item.content }}
                    </div>
                    <div class="data" style="padding-bottom: 10px">
                      <span>{{ item.praise_num }}赞</span>
                      <span>·</span>
                      <span>{{ item.comment_num }}评论</span>
                      <span>·</span>
                      <span>{{ item.browse_num | pageviews }} 浏览</span>
                      <span>·</span>
                      <span>{{ item.create_time | formaTime }}</span>
                    </div>
                  </div>
                </div>

                <!-- 指南解析 -->
                <div
                  class="group-one-right2"
                  v-if="item.type == 4"
                  @click="xin.goUrl('/found-details-details?id=' + item.id)"
                >
                  <div class="group-one-top" v-if="item.doctor">
                    <img
                      class="avatar"
                      :src="
                        item.doctor.photo
                          ? item.doctor.photo
                          : require('@image/default.png')
                      "
                      @click.stop="goPersonal"
                    />
                    <div class="group-one-top-right">
                      <div class="one">
                        <span class="title">{{ item.doctor.name }}</span>
                        <span class="position">{{
                          item.doctor.title_name
                        }}</span>
                      </div>
                      <span class="address"
                        >{{ item.doctor.company }}
                        {{ item.doctor.department_name }}</span
                      >
                    </div>
                  </div>
                  <span
                    v-html="item.title"
                    class="subTitle"
                    ref="investigateRef"
                  >
                  </span>
                  <span v-html="item.content" class="subTitle2"></span>
                  <div class="group3">
                    <span>疾病名称：</span>
                    <span>{{ item.disease }}</span>
                  </div>
                  <div class="group2">
                    <span>搜索词：</span>
                    <span>{{ item.search }}</span>
                  </div>

                  <div class="data">
                    <span>{{ item.praise_num }}赞</span>
                    <span>·</span>
                    <span>{{ item.comment_num }}评论</span>
                    <span>·</span>
                    <span>{{ item.browse_num | pageviews }}浏览</span>
                    <span>·</span>
                    <span>{{ item.create_time | formaTime }}</span>
                  </div>
                </div>

                <!-- 问答 -->
                <div
                  class="group-two"
                  v-if="item.type == 5"
                  @click="xin.goUrl('/found-qa?id=' + item.id)"
                >
                  <span class="title" ref="investigateRef">{{
                    item.title
                  }}</span>
                  <div class="bottom">
                    <div class="banner3" v-if="item.images.length">
                      <img :src="item.images[0]" />
                      <div class="banner3-icon">{{ item.images.length }}图</div>
                    </div>
                    <div class="group-two-div">
                      <span
                        v-html="item.content"
                        class="subTitle"
                        ref="investigateRef"
                      ></span>
                      <div class="group-two-bottom">
                        <img
                          class="avatar"
                          :src="
                            item.doctor.photo
                              ? item.doctor.photo
                              : require('@image/default.png')
                          "
                          @click.stop="goPersonal"
                        />
                        <span>{{ item.doctor.name }}</span>
                        <span>·</span>
                        <span>{{ item.doctor.title_name }}</span>
                        <span>·</span>

                        <span>{{ item.browse_num | pageviews }}浏览</span>
                        <span>·</span>
                        <span>{{ item.create_time | formaTime }}</span>
                        <span class="button">去回答</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- 试卷 -->
              <div class="list" v-if="item.search_type == 6">
                <div
                  class="content-group"
                  @click="
                    $router.push({
                      path: '/index-mockExam-answerSheet',
                      query: { serial_code: item.serial_code },
                    })
                  "
                >
                  <span class="group-title" ref="investigateRef">{{
                    item.caption
                  }}</span>
                  <div class="time">
                    <span>总题量：</span>
                    <span class="minute">{{ item.item_count }}题</span>
                    <span>考试时间：</span>
                    <span class="minute">{{ item.total_minute }}分钟</span>
                    <span>总分值：</span>
                    <span class="minute">{{ item.total_score }}分</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="foot-page" v-if="total > limit">
              <el-pagination
                @size-change="handleSizeChange($event, true)"
                @current-change="handleCurrentChange($event, true)"
                current-page.sync="5"
                :page-size="limit"
                layout="prev, pager, next, jumper"
                :total="total"
              >
              </el-pagination>
            </div>
          </div>
        </el-tab-pane>
        <!-- 视频 -->
        <el-tab-pane label="视频" name="video">
          <div class="left-group" v-loading="loading">
            <div class="null-box" v-if="!list.length">
              <img
                :src="$http.baseURL + '/static/images/web/ecf28ef18a8e85ff.png'"
                alt=""
              />
              <div
                class="title"
                style="font-size: 14px !important; color: #969799 !important"
              >
                暂无相关内容
              </div>
            </div>

            <div
              class="content2"
              v-for="(item, index) in list"
              :key="index"
              @click="xin.goUrl('/found-details-videoDetails?id=' + item.id)"
            >
              <div class="left">
                <div class="play">
                  <i class="iconfont zanting"></i>
                </div>
                <img class="banner2" :src="item.video_img" />
              </div>
              <div class="right">
                <span ref="investigateRef" class="title">{{ item.title }}</span>
                <span class="subTitle" v-html="item.content"></span>
                <div class="subTitle2">
                  <span>{{ item.browse_num }}浏览</span>
                  <span class="time">{{ item.create_time | formaTime }}</span>
                </div>
              </div>
            </div>

            <div class="foot-page" v-if="total > limit">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                current-page.sync="5"
                :page-size="limit"
                layout="prev, pager, next, jumper"
                :total="total"
              >
              </el-pagination>
            </div>
          </div>
        </el-tab-pane>
        <!-- 用户 -->
        <el-tab-pane label="用户" name="user">
          <div v-loading="loading">
            <div class="null-box" v-if="!list.length">
              <img
                :src="$http.baseURL + '/static/images/web/ecf28ef18a8e85ff.png'"
                alt=""
              />
              <div class="title">暂无相关内容</div>
            </div>

            <div class="group" v-for="(item, index) in list" :key="index">
              <img :src="item.avatar" alt="" />
              <div class="intermediate">
                <span ref="investigateRef">{{ item.nickname }}</span>
                <div class="numberFans">
                  <span>粉丝数：</span>
                  <span>{{ item.attention_num }}</span>
                </div>
              </div>
              <span
                v-if="item.user_id != userInfo.id"
                class="attention"
                :class="{ attention1: item.attention }"
                @click="attentionFn(item)"
                >{{ item.attention ? "已关注" : "关注" }}</span
              >
            </div>

            <div class="foot-page" v-if="total > limit">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                current-page.sync="5"
                :page-size="limit"
                layout="prev, pager, next, jumper"
                :total="total"
              >
              </el-pagination>
            </div>
          </div>
        </el-tab-pane>
        <!-- 调研 -->
        <el-tab-pane label="调研" name="research">
          <div class="content" v-loading="loading">
            <div v-if="!list.length" class="null-box">
              <img
                :src="$http.baseURL + '/static/images/web/ecf28ef18a8e85ff.png'"
                alt=""
                srcset=""
              />
              <div class="title">暂无相关内容</div>
            </div>

            <div
              class="list"
              v-for="(item, index) in list"
              :key="index"
              @click="researchFn(item, 1)"
            >
              <div class="img">
                <img class="banner2" :src="item.url" />
                <div
                  class="mark"
                  :class="[
                    { 'lan-tab': item.type == 3, 'red-tab': item.type == 2 },
                  ]"
                >
                  <span>{{ arr[item.type - 1] }}</span>
                </div>
              </div>
              <div class="left-onLine-right">
                <div ref="investigateRef" class="inerhtml">
                  <span class="sheng">{{ item.title }}</span>
                  <span class="subTitle sheng_2" v-html="item.present"></span>
                </div>
                <div class="right-button">
                  <span class="blue left-btn">{{ item.duration }}分钟</span>
                  <span class="blue left-btn" v-if="item.cash_gift"
                    >¥{{ item.cash_gift }}</span
                  >
                  <span
                    class="blue recommend-yellow"
                    @click.stop="researchFn(item, 2)"
                    >我要推荐</span
                  >
                </div>
              </div>
            </div>

            <div class="foot-page" v-if="total > limit">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                current-page.sync="5"
                :page-size="limit"
                layout="prev, pager, next, jumper"
                :total="total"
              >
              </el-pagination>
            </div>
          </div>
        </el-tab-pane>
        <!-- 药品 -->
        <el-tab-pane label="药品" name="drugs">
          <div class="content" v-loading="loading">
            <div v-if="!drugsList.length" class="null-box">
              <img
                :src="$http.baseURL + '/static/images/web/ecf28ef18a8e85ff.png'"
                alt=""
                srcset=""
              />
              <div class="title">暂无相关内容</div>
            </div>

            <ul class="drugs">
              <li v-for="item in drugsList" :key="item.id">
                <div class="img" v-if="item.src">
                  <img :src="item.src" alt="" />
                </div>
                <div class="desc">
                  <h4>{{ item.name }}</h4>
                  <p class="word">{{ item.specs }}</p>
                  <p class="btn">
                    <el-button
                      v-show="item.src"
                      round
                      v-for="it in item.type"
                      :key="it"
                    >
                      {{ it }}
                    </el-button>
                  </p>

                  <i v-show="!item.src" class="el-icon-arrow-right"></i>
                </div>
              </li>
            </ul>

            <div class="foot-page" v-if="total > limit">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                current-page.sync="5"
                :page-size="limit"
                layout="prev, pager, next, jumper"
                :total="total"
              >
              </el-pagination>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </section>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      isReq: true,
      title: "", //搜索内容
      activeName: "all",
      page: 1,
      limit: 10,
      total: 0,
      list: "",
      arr: ["问卷调研", "电话调研", "面访调研"],
      loading: false,
      drugsList: [
        {
          id: 1,
          name: "药物名称展示1详情",
          specs: "剂型规格展示",
          type: ["处方药", "非医保", "基药"],
          src: "https://testadmin.idr.cn/uploads/admin/guide/2021/08/19/162936705689238.jpg",
        },
        {
          id: 2,
          name: "药物名称展示2详情",
          specs: "片剂10mg,20mg",
          type: ["处方药", "非医保", "基药"],
          src: "https://testadmin.idr.cn/uploads/admin/guide/2021/08/19/162936705689238.jpg",
        },
        {
          id: 3,
          name: "药物名称展示3详情",
          specs: "片剂10mg,20mg",
          type: ["处方药", "非医保", "基药"],
          src: "",
        },
      ],
    };
  },
  watch: {
    // $route(reo){
    // let val = reo.query.val;
    // if(val){
    //     this.title = val;
    // }
    // },
    // title(val){
    //   const newUrl = `#/title?val=${val}`
    //   window.history.replaceState('', '', newUrl)
    // }
  },
  created() {
    let val = this.$route.query.val;
    if (val) {
      this.title = val;
    }
  },
  computed: {
    ...mapState("userManagement", ["userInfo"]),
    listFilter() {
      let list = [],
        arr = [];
      var result = [];
      for (var i = 0; i < this.list.length; i += this.limit) {
        list.push(this.list.slice(i, i + this.limit));
      }
      return list;
    },
  },
  methods: {
    goPersonal() {
      let url = "/found-personal?id=" + this.item.doctor.user_id;
      this.xin.goUrl(url);
    },
    //关注
    async attentionFn(item) {
      let token = localStorage.getItem("token") || "";
      if (!token) {
        return this.$message({ message: "请登录后操作", type: "error" });
      }
      if (this.isReq) {
        this.isReq = false;
        let res = await this.$userApi.postDiscoverAttention({
          attention_id: item.id,
        });
        this.isReq = true;
        if (res.code == 200) {
          if (item.attention) {
            this.$message({ message: "已取消关注", type: "success" });
          } else {
            this.$message({ message: "关注成功", type: "success" });
          }
          item.attention = !item.attention;
        }
      }
    },
    // 调研
    researchFn(item, type) {
      let token = localStorage.getItem("token") || "";
      if (!token) {
        return this.$message({ message: "请登录后操作", type: "error" });
      } else if (!this.userInfo.company || !this.userInfo.company.company) {
        return this.$message({ message: "请身份认证后操作", type: "error" });
      }
      if (type == 1) {
        this.xin.goUrl("/research-questionnaireTelephone-index?id=" + item.id);
      } else {
        this.xin.goUrl("/research-recommend-index?id=" + item.id);
      }
    },

    rendering() {
      //指定内容渲染标红
      let title = this.title.trim();
      if (!title) {
        return;
      }
      let con = this.$refs.investigateRef;
      var reg = new RegExp(this.title, "ig");
      for (let i in con) {
        con[i].innerHTML = con[i].innerText.replace(
          reg,
          "<span style='color: red;'>$&</span>"
        );
      }
    },
    async handleSizeChange(e, flag) {
      this.page = e;
      if (flag) {
        return;
      }
      await this.searchFun();
    },
    // 分页
    async handleCurrentChange(e, flag) {
      this.page = e;
      if (flag) {
        return;
      }
      await this.searchFun();
    },
    searchBtn() {
      this.list = [];
      this.page = 1;
      this.searchFun();
    },
    async searchFun() {
      //搜索
      if (!this.loading) {
        let title = this.title.trim();
        let data = {
          title,
        };
        if (!title) {
          this.total = 0;
          return this.$message.error("请输入关键词搜索");
        }
        if (this.activeName == "all") {
          this.loading = true;
          let res = await this.$creationApi.searchAll(data);
          this.loading = false;
          if (res.code == 200) {
            let list = res.data || [];
            for (let i in list) {
              if (list[i].search_type == 5) {
                if (list[i].images) {
                  list[i].images = JSON.parse(list[i].images);
                }
              }
            }
            this.list = list;
            this.total = res.data.length;
          }
        } else {
          let type = 1;
          if (this.activeName == "user") {
            type = 2;
          } else if (this.activeName == "research") {
            type = 3;
          }
          data.page = this.page;
          data.limit = this.limit;
          data.type = type;

          this.loading = true;
          let res = await this.$creationApi.searchList(data);
          this.loading = false;
          if (res.code == 200) {
            this.list = res.data.data || [];
            this.total = res.data.total;
          }
        }
        this.$nextTick(() => {
          this.rendering();
        });
      }
    },

    handleClick(tab, event) {
      this.list = [];
      this.page = 1;
      let title = this.title.trim();
      if (!title) {
        this.total = 0;
        return;
      }
      this.searchFun();
    },
  },
};
</script>

<style lang='scss' scoped>
@import "@assets/css/themeColor.scss";

.content-group {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  color: #8f8f8f;
  font-size: 24px;
  width: 100%;
  background: #ffffff;
  // box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  padding: 18px 30px;
  box-sizing: border-box;
  > .group-title {
    color: #3aa5ea;
  }
  > .time {
    font-size: 14px;
    margin-top: 16px;
    .minute {
      color: #333333;
      margin-right: 30px;
    }
  }
}

.contentWJ {
  border: 1px solid red;
  padding: 18px 30px 20px 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between; //让自动分来
  .content-group {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    color: #8f8f8f;
    font-size: 24px;
    width: 560px;
    background: #ffffff;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    padding: 18px 30px;
    box-sizing: border-box;
    margin-bottom: 40px;
    > .group-title {
      color: #3aa5ea;
    }
    > .time {
      font-size: 14px;
      margin-top: 16px;
      .minute {
        color: #333333;
        margin-right: 30px;
      }
    }
  }
}

.foot-page {
  height: 40px;
  text-align: center;
  width: 100%;
  ::v-deep .el-input {
    width: 50px !important;
    height: 24px !important;
    margin-top: 0 !important;
  }
}

::v-deep {
  .el-input {
    width: 1200px;
    height: 50px;
    background: #ffffff;
    // border: 1px solid $--themeColor;
    border-radius: 6px;
    margin-top: 30px;
    .el-input__inner {
      height: 100%;
    }
    > .el-input-group__append,
    .el-input-group__prepend {
      height: 50px;
      background: $--themeColor;
      color: #ffffff;
      border: none;
    }
  }
  .el-tabs .el-tabs__item.is-active {
    color: $--themeColor;
    transform: scale(1);
    font-weight: 400;
  }
  .el-tabs__header {
    margin-top: 30px;
  }
  .el-tabs .el-tabs__active-bar {
    display: none;
  }
  .el-tabs__nav-wrap::after {
    background: none;
  }
  .el-tabs__content {
    width: 1200px;
    background: #ffffff;
    padding: 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }
  .el-tabs .el-tabs__header {
    margin-bottom: 0;
  }
}
.page {
  background: #f2f2f2;
  > section {
    display: flex;
    flex-direction: column;
    .group {
      display: flex;
      flex-direction: row;
      align-items: center;
      border-bottom: 1px solid #f2f2f2;
      padding-bottom: 30px;
      box-sizing: border-box;
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 0;
        border-bottom: none;
      }
      > img {
        width: 70px;
        height: 70px;
        object-fit: cover;
        // border: 1px solid $--themeColor;
        border-radius: 50%;
      }
      > .intermediate {
        display: flex;
        flex-direction: column;
        font-size: 24px;
        color: #333;
        margin-left: 30px;
        > .numberFans {
          display: flex;
          flex-direction: row;
          font-size: 16px;
          color: #8f8f8f;
          margin-top: 10px;
        }
      }
      .attention {
        cursor: pointer;
        width: 120px;
        height: 40px;
        background: $--themeColor;
        border-radius: 5px;
        color: #ffffff;
        text-align: center;
        line-height: 40px;
        margin-left: auto;
        font-size: 20px;
      }
      .attention1 {
        background: #ffffff !important;
        border: 1px solid #8f8f8f !important;
        color: #8f8f8f !important;
      }
    }

    .left-onLine {
      cursor: pointer;
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      overflow: hidden;
      padding-bottom: 30px;
      box-sizing: border-box;
      border-bottom: 1px solid #f2f2f2;
      margin-bottom: 30px;
      > .img {
        width: 150px;
        height: 150px;
        overflow: hidden;
        > .banner2 {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        > .mark {
          background: #f6e06a;
          width: 120px;
          height: 24px;
          transform: rotate(307deg); //旋转
          position: absolute;
          text-align: center;
          left: -35px;
          top: 25px;
          line-height: 24px;
          color: #ffffff;
          font-size: 12px;
        }
      }
      .left-onLine-right {
        width: calc(100% - 150px - 30px);
        display: flex;
        flex-direction: column;
        color: #333333;
        font-size: 24px;
        .subTitle {
          font-size: 18px;
          color: #8f8f8f;
          margin-top: 10px;
        }
        .right-button {
          display: flex;
          flex-direction: row;
          font-size: 18px;
          color: #ffffff;
          margin-top: auto;
          > .blue {
            cursor: pointer;
            &:nth-child(2) {
              width: 96px;
              margin-left: 30px;
            }
            &:last-child {
              width: 132px;
              background: #f6e06a;
              color: #333333;
              margin-left: auto;
            }
            width: 110px;
            height: 44px;
            background: linear-gradient(90deg, $--themeColor, #46cbca);
            border-radius: 5px;
            text-align: center;
            line-height: 44px;
          }
        }
      }
    }

    .left-group {
      display: flex;
      flex-direction: column;
      //   padding-bottom: 30px;
      box-sizing: border-box;
      .title {
        font-size: 24px;
        color: #333333;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .content2 {
        position: relative;
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid #f2f2f2;
        padding-bottom: 30px;
        box-sizing: border-box;
        margin-bottom: 30px;
        .left {
          position: relative;
          cursor: pointer;
          width: 270px;
          .play {
            z-index: 5;
            position: absolute;
            left: 50%;
            top: 50%;
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 40px;
            height: 40px;
            margin-top: -20px;
            margin-left: -20px;
            overflow: hidden;
            opacity: 0.7;
            background: #000000;
            border-radius: 50%;
            font-size: 12px;
            color: #ffffff;
            .zanting {
              margin: 0 5px 0 11px;
            }
          }
          .banner2 {
            width: 270px;
            height: 190px;
            border-radius: 10px;
            object-fit: cover;
          }
        }
        .right {
          cursor: pointer;
          display: flex;
          flex: 1;
          flex-direction: column;
          margin-left: 20px;
          line-height: 1.8;
          color: #8f8f8f;
          font-size: 18px;
          > .title {
            color: #333333;
            font-size: 24px;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .subTitle {
            overflow: hidden;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          > .subTitle2 {
            margin-top: auto;
            > .time {
              margin-left: 20px;
            }
          }
        }
      }
      > .group1 {
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid #f2f2f2;
        padding-bottom: 30px;
        box-sizing: border-box;
        position: relative;
        > .play {
          z-index: 5;
          position: absolute;
          left: 220px;
          top: 114px;
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 79px;
          height: 26px;
          opacity: 0.3;
          background: #000000;
          border-radius: 13px;
          font-size: 12px;
          color: #ffffff;
          > .zanting {
            margin: 0 5px 0 11px;
          }
        }
        > .banner {
          width: 310px;
          height: 150px;
          object-fit: cover;
          margin-right: 20px;
        }
        > .video {
          display: flex;
          flex-direction: column;
          color: #8f8f8f;
          margin-bottom: 15px;
          > .title {
            font-size: 18px;
            color: #333333;
          }
          > .browse {
            display: flex;
            flex-direction: row;
            margin-top: 10px;
            > .time {
              margin-left: 15px;
            }
          }
        }
      }
    }
  }
}

.content {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  // min-height: 400px;
  .list {
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow: hidden;
    padding-bottom: 30px;
    box-sizing: border-box;
    margin-bottom: 30px;
    border-bottom: 1px solid #f2f2f2;
    .right-button {
      margin-top: auto;
      .blue {
        color: #fff;
        height: 44px;
        padding: 10px 30px;
        border-radius: 5px;
        box-sizing: border-box;
        font-size: 18px;
        line-height: 22px;
        cursor: pointer;
      }
      .left-btn {
        background: linear-gradient(90deg, $--themeColor, #46cbca);
        float: left;
        margin-right: 30px;
      }
      .recommend-yellow {
        background: #f6e06a;
        color: #333;
        float: right;
      }
      .close-btn {
        background: #e60012;
        margin-left: 30px;
        float: right;
      }
    }
    &:nth-child(2n) {
      > .left-onLine-right {
        .right-button {
          > .recommend-yellow {
            // background: #FFA364;
          }
        }
      }
    }
    .img {
      width: 150px;
      height: 150px;
      overflow: hidden;
      > .banner2 {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .mark {
        background: #f6e06a;
        width: 120px;
        height: 24px;
        transform: rotate(307deg); //旋转
        position: absolute;
        text-align: center;
        left: -35px;
        top: 25px;
        line-height: 24px;
        color: #ffffff;
        font-size: 12px;
      }
      .red-tab {
        background: #e60012;
      }
      .lan-tab {
        background: $--themeColor;
      }
      .hui-tab {
        background: #0005;
      }
    }

    .left-onLine-right {
      width: calc(100% - 150px - 30px);
      // flex-direction: row-reverse;
      display: flex;
      flex-direction: column;
      color: #333333;
      font-size: 24px;
      .subTitle {
        font-size: 18px;
        color: #8f8f8f;
        margin-top: 10px;
        line-height: 1.4;
      }
    }
  }

  .drugs {
    li {
      display: flex;
      padding: 20px 0;
      border-bottom: 1px solid #d9d9d9;
      align-items: center;
      cursor: pointer;

      .img {
        width: 120px;
        height: 73px;
        border: 1px solid #d9d9d9;
        border-radius: 10px;
        position: relative;
        margin-right: 20px;

        img {
          width: 95px;
          height: 56px;
          object-fit: cover;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        }
      }

      img {
        width: 120px;
        height: 73px;
        border-radius: 10px;
        object-fit: cover;
      }

      .desc {
        flex: 1;
        position: relative;
        padding-right: 35px;

        h4 {
          font-size: 18px;
        }

        .word {
          font-size: 14px;
          color: #8e8e8e;
          margin: 8px 0;
        }

        .btn {
          button {
            height: 22px;
            padding: 0 15px;
            font-size: 14px;
            border-color: $--themeColor;
            color: $--themeColor;
          }
        }

        .el-icon-arrow-right {
          position: absolute;
          color: #717171;
          font-weight: 600;
          right: 20px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}
.subTitle /deep/ img {
  max-width: 100%;
  max-height: 150px;
}
.left-group {
  display: flex;
  flex-direction: column;
  // padding-bottom: 30px;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;

  .banner2 {
    width: 270px;
    height: 200px;
    object-fit: cover;
    border-radius: 10px;
    margin-right: 20px;
  }
  .title {
    font-size: 24px;
    color: #333333;
    // margin-bottom: 10px;
    // margin-right: 20px;
  }
  .content {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #f2f2f2;
    padding-bottom: 30px;
    box-sizing: border-box;
    margin-bottom: 30px;
    font-size: 18px;
    margin-top: 10px;
    .banner {
      width: 145px;
      height: 100px;
      border-radius: 10px;
    }
    .right {
      display: flex;
      flex-direction: column;
      color: #8f8f8f;
      font-weight: 18px;
      margin-left: 20px;
      line-height: 1.8;
      .subTitle2 {
        display: flex;
        flex-direction: row;
        margin-top: auto;
        > .time {
          margin-left: 20px;
        }
      }
    }
  }
  .content2 {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #f2f2f2;
    padding-bottom: 30px;
    box-sizing: border-box;
    margin-bottom: 30px;
    .img-s {
      position: relative;
      width: 270px;
      height: 190px;
      .rectangular {
        width: 40px;
        height: 40px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0.7;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .banner2 {
      width: 270px;
      height: 190px;
      border-radius: 10px;
      object-fit: cover;
    }
    .right {
      display: flex;
      flex-direction: column;
      margin-left: 20px;
      line-height: 1.8;
      color: #8f8f8f;
      font-size: 18px;
      .title {
        color: #333333;
        font-size: 24px;
      }
      .subTitle2 {
        margin-top: auto;
        .time {
          margin-left: 20px;
        }
      }
    }
  }
  .right {
    display: flex;
    flex-direction: column;
    // border-bottom: 1px solid #f2f2f2;
    // padding-bottom: 30px;
    box-sizing: border-box;
    // margin-bottom: 30px;
    line-height: 1.8;
    color: #8f8f8f;
    font-size: 18px;
    .title {
      color: #333333;
      font-size: 24px;
    }
    .subTitle2 {
      margin-top: 20px;
      .time {
        margin-left: 20px;
      }
    }
  }
}

.group-one {
  display: flex;
  margin-top: 23px;
  padding-bottom: 30px;
  box-sizing: border-box;
  border-bottom: 1px solid #f2f2f2;
  cursor: pointer;
  margin-bottom: 30px;
  width: 890px;
  > .group-one-box2 {
    position: relative;
    margin-right: 20px;
    > .rectangular2 {
      position: absolute;
      width: 50px;
      height: 26px;
      opacity: 0.3;
      background: #000000;
      border-radius: 13px;
      font-size: 14px;
      color: #ffffff;
      text-align: center;
      line-height: 26px;
      right: 10px;
      bottom: 10px;
    }
    > .banner2 {
      width: 270px;
      height: 200px;
      object-fit: cover;
      border-radius: 10px;
    }
    > .banner5 {
      width: 270px;
      height: 223px;
      object-fit: cover;
      border-radius: 10px;
    }
  }
  > .group-one-box {
    width: 270px;
    position: relative;
    margin-right: 20px;
    > .rectangular {
      position: absolute;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      opacity: 0.7;
      background: #000000;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 12px;
      color: #ffffff;
      > img {
        width: 16px;
        height: 18px;
        object-fit: cover;
      }
    }
    > .banner {
      width: 270px;
      height: 190px;
      object-fit: cover;
      border-radius: 10px;
    }
  }
  > .group-one-right {
    cursor: pointer;
    flex: 1;
    border: none;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    > .subTitle2 {
      font-size: 18px;
      color: #8f8f8f;
      line-height: 1.8;
      margin-bottom: 10px;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      /deep/ img {
        // max-width: 100%;
        max-width: 100%;
        max-height: 300px;
      }
    }
    > .group-one-top {
      display: flex;
      align-items: center;
      position: relative;
      .group-one-top-right {
        flex: 1;
        display: flex;
        flex-direction: column;
        > .one {
          display: flex;
          flex-direction: row;
          align-items: center;
          > .title {
            font-size: 24px;
            color: #333333;
          }
          > .position {
            font-size: 13px;
            color: $--themeColor;
            height: 25px;
            border: 1px solid $--themeColor;
            border-radius: 6px;
            text-align: center;
            line-height: 25px;
            margin-left: 12px;
            padding: 0 13px;
            box-sizing: border-box;
          }
          > .button {
            position: absolute;
            right: 0;
            width: 56px;
            height: 25px;
            background: $--themeColor;
            border-radius: 5px;
            color: #ffffff;
            text-align: center;
            line-height: 26px;
            margin-left: auto;
            font-size: 12px;
          }
          > .button2 {
            width: 66px;
            height: 25px;
            border: 1px solid #8f8f8f;
            border-radius: 5px;
            color: #8f8f8f;
            text-align: center;
            line-height: 26px;
            margin-left: auto;
            font-size: 12px;
          }
        }
        > .address {
          font-size: 16px;
          color: #8f8f8f;
          margin-top: 9px;
        }
      }
      > img {
        width: 70px;
        height: 70px;
        object-fit: cover;
        margin-right: 12px;
      }
    }
    > .line {
      min-height: 88px;
    }
    > .subTitle {
      font-size: 24px;
      color: #333333;
      margin-bottom: 10px;
      line-height: 44px;
      height: 44px;
      box-sizing: border-box;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      s /deep/ img {
        // max-width: 100%;
        max-width: 100%;
        max-height: 300px;
      }
    }
    .data {
      display: flex;
      align-items: center;
      font-size: 18px;
      line-height: 20px;
      height: 30px;
      color: #8f8f8f;
      span {
        margin-right: 16px;
      }
      .data-bh {
        color: #e60012;
      }
      .data-icon {
        width: 24px;
        height: 24px;
      }
    }
    .creationTitle {
      font-size: 13px;
      font-weight: 400;
      color: $--themeColor;
      padding: 3px 12px;
      border: 1px solid $--themeColor;
      border-radius: 6px;
      margin-right: 10px;
      line-height: 20px;
    }
  }
}
.group-two {
  width: 890px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 18px;
  color: #8f8f8f;
  padding-bottom: 30px;
  box-sizing: border-box;
  border-bottom: 1px solid #f2f2f2;
  margin-bottom: 30px;
  > .bottom {
    display: flex;
    margin-top: 10px;
    width: 890px;
    > .banner3 {
      width: 145px;
      height: 100px;
      margin-right: 20px;
      position: relative;
      img {
        width: 145px;
        height: 100px;
        object-fit: cover;
        border-radius: 10px;
      }
      .banner3-icon {
        position: absolute;
        width: 50px;
        height: 26px;
        opacity: 0.3;
        background: #000000;
        border-radius: 13px;
        font-size: 14px;
        color: #ffffff;
        text-align: center;
        line-height: 26px;
        bottom: 10px;
        right: 10px;
      }
    }
    > .group-two-div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
      flex-wrap: wrap;
      > .title {
        font-size: 24px;
        color: #333333;
        height: 43px;
        // margin-bottom: 10px;
      }
      > .subTitle {
        line-height: 1.8;
        overflow: hidden;
        display: block;
        width: 100%;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        /deep/ img {
          max-width: 100%;
          max-height: 300px;
        }
      }
      .group-two-bottom {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 18px;
        color: #8f8f8f;
        margin-top: auto;
        height: 40px;
        .data-bh {
          color: #e60012;
        }
        img {
          width: 30px;
          height: 30px;
          object-fit: cover;
          margin-right: 10px;
        }
        span {
          margin-right: 16px;
          &:last-child {
            margin-right: 0;
          }
        }
        .creationTitle {
          font-size: 13px;
          font-weight: 400;
          color: $--themeColor;
          padding: 3px 12px;
          border: 1px solid $--themeColor;
          border-radius: 6px;
          margin-right: 10px;
          line-height: 20px;
        }
        .button {
          font-size: 14px;
          color: $--themeColor;
          width: 89px;
          height: 40px;
          background: #dbfbf8;
          border-radius: 5px;
          text-align: center;
          line-height: 40px;
          margin-left: auto;
          font-size: 12px;
        }
      }
    }
  }
  > .title {
    font-size: 24px;
    color: #333333;
    height: 43px;
    line-height: 43px;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  > .subTitle {
    line-height: 1.8;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    /deep/ img {
      max-width: 100%;
      max-height: 300px;
    }
  }
  > .group-two-bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 18px;
    color: #8f8f8f;
    margin-top: 15px;
    > img {
      width: 30px;
      height: 30px;
      object-fit: cover;
      margin-right: 10px;
    }
    > span {
      margin-right: 16px;
      &:last-child {
        margin-right: 0;
      }
    }
    > .button {
      font-size: 12px;
      color: $--themeColor;
      width: 89px;
      height: 40px;
      background: #dbfbf8;
      border-radius: 5px;
      text-align: center;
      line-height: 40px;
      margin-left: auto;
    }
  }
}
.group-one-right2 {
  width: 890px;
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: 30px;
  cursor: pointer;
  > .subTitle2 {
    font-size: 18px;
    color: #8f8f8f;
    line-height: 1.8;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    /deep/ img {
      // max-width: 100%;
      max-width: 100%;
      max-height: 300px;
    }
  }
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  color: #8f8f8f;
  font-size: 18px;
  > .group-one-top {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    > .group-one-top-right {
      flex: 1;
      display: flex;
      flex-direction: column;
      > .one {
        display: flex;
        flex-direction: row;
        align-items: center;
        > .title {
          font-size: 24px;
          color: #333333;
        }
        > .position {
          font-size: 13px;
          color: $--themeColor;
          padding: 0 13px;
          box-sizing: border-box;
          height: 25px;
          border: 1px solid $--themeColor;
          border-radius: 6px;
          text-align: center;
          line-height: 25px;
          margin-left: 12px;
        }
        > .button {
          position: absolute;
          right: 0;
          width: 56px;
          height: 25px;
          background: $--themeColor;
          border-radius: 5px;
          color: #ffffff;
          text-align: center;
          line-height: 26px;
          margin-left: auto;
          font-size: 12px;
        }
        > .button2 {
          width: 66px;
          height: 25px;
          border: 1px solid #8f8f8f;
          border-radius: 5px;
          color: #8f8f8f;
          text-align: center;
          line-height: 26px;
          margin-left: auto;
          font-size: 12px;
        }
      }
      > .address {
        font-size: 16px;
        color: #8f8f8f;
        margin-top: 9px;
      }
    }
  }
  > .subTitle {
    font-size: 24px;
    color: #333333;
    margin-bottom: 10px;
    line-height: 1.8;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    /deep/ img {
      max-width: 100%;
      max-height: 300px;
    }
  }
  .data {
    display: flex;
    align-items: center;
    font-size: 18px;
    line-height: 20px;
    height: 30px;
    color: #8f8f8f;
    span {
      margin-right: 16px;
    }
    .data-bh {
      color: #e60012;
    }
    .data-icon {
      width: 24px;
      height: 24px;
    }
  }
  .creationTitle {
    font-size: 13px;
    font-weight: 400;
    color: $--themeColor;
    padding: 3px 12px;
    border: 1px solid $--themeColor;
    border-radius: 6px;
    margin-right: 10px;
  }
  > .group3 {
    display: flex;
    flex-direction: row;
    margin-top: 7px;
    margin-bottom: 10px;
  }
  > .group2 {
    margin-bottom: 20px;
  }
}
.flex-div {
  display: flex;
  justify-content: space-between;
  .delete-find {
    .iconfont {
      padding: 10px;
      &:hover {
        color: #333333;
      }
      color: #8f8f8f;
    }
  }
}
.widwd {
  /deep/ {
    span,
    p {
      background: transparent !important;
    }
  }
}
.avatar {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 12px;
}
</style>